import Quill from "quill";

export const Parchment = Quill.import("parchment");

class IndentAttributor extends Parchment.Attributor.Style {
    multiplier = 2;

    constructor(name: string, style: string, params: any) {
        super(name, style, params);
    }

    add(node: HTMLElement, value: string | number) {
        const currentIndent = this.value(node);
        let nextIndent = 0;
        if (typeof value === 'number') nextIndent = value;
        else if (value === '+1') nextIndent = currentIndent + 1;
        else if (value === '-1') nextIndent = currentIndent - 1;
        nextIndent = Math.min(nextIndent, 8);

        if (nextIndent <= 0) {
            super.remove(node);
            return true;
        }

        return super.add(node, `${nextIndent}rem`);
    }

    value(node): number {
        return parseFloat(super.value(node)) || 0;
    }
}


export const IndentStyle = new IndentAttributor("indent", "margin-left", {
    scope: Parchment.Scope.BLOCK,
    whitelist: [1, 2, 3, 4, 5, 6, 7, 8].map(v => `${v}rem`),
})
