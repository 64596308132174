import { Directive, HostListener, Input, NgZone, Optional } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[cwtCloseDrawer]'
})
export class CloseDrawerDirective {

  @Input('cwtCloseDrawer') data: any;

  constructor(@Optional() private drawer: NzDrawerRef) { }

  @HostListener('click', ['$event'])
  public close(event: Event) {
    if (!this.drawer) return;
    event?.stopPropagation();
    event?.preventDefault();
    this.drawer.close(this.data);
  }
}
