import { Directive, ElementRef, EventEmitter, Host, HostListener, Input, Output, ViewContainerRef } from '@angular/core';
import { CwtStopPropagationDirective } from '@cawita/core-front';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { ConfirmType, NzModalModule, NzModalService } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[cwtConfirm]',
  hostDirectives: [CwtStopPropagationDirective],
})
export class ConfirmDirective {

  @Input() confirmType: ConfirmType;
  @Input() confirmTitle: string;
  @Input() confirmMessage: string;
  @Input() @InputBoolean() disabled: BooleanInput;
  @Input() @InputBoolean() nzDisabled: BooleanInput;

  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private vcRef: ViewContainerRef,
    private modal: NzModalService
  ) { }

  @HostListener('click')
  public onClick() {

    if (this.disabled || this.nzDisabled) return;
    const iconMap = {
      confirm: 'question-circle',
      info: 'info-circle',
      success: 'check-circle',
      error: 'close-circle',
      warning: 'exclamation-circle'
    };

    this.modal.confirm({
      nzTitle: this.confirmTitle,
      nzContent: this.confirmMessage,
      nzCentered: true,
      nzViewContainerRef: this.vcRef,
      nzIconType: iconMap[this.confirmType ?? 'confirm'] ?? iconMap['confirm'],
      nzOnCancel: () => this.cancel.next(),
      nzOnOk: () => this.confirm.next(),
    }, this.confirmType);
  }


}
