import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'cwt-page-header-title, [cwt-page-header-title]',
  exportAs: 'cwtPageHeaderTitle',
  host: { class: 'cwt-page-header-title' }
})
export class PageHeaderTitleDirective { }


@Directive({
  selector: 'cwt-page-header-subtitle, [cwt-page-header-subtitle]',
  exportAs: 'cwtPageHeaderSubtitle',
  host: { class: 'cwt-page-header-subtitle' }
})
export class PageHeaderSubtitleDirective { }

@Directive({
  selector: 'cwt-page-header-actions, [cwt-page-header-actions]',
  exportAs: 'cwtPageHeaderActions',
  host: { class: 'cwt-page-header-actions' }
})
export class PageHeaderActionsDirective { }

@Directive({
  selector: 'cwt-page-header-tags, [cwt-page-header-tags]',
  exportAs: 'cwtPageHeaderTags',
  host: { class: 'cwt-page-header-tags' }
})
export class PageHeaderTagsDirective { }

@Directive({
  selector: 'cwt-page-header-content, [cwt-page-header-content]',
  exportAs: 'cwtPageHeaderContent',
  host: { class: 'cwt-page-header-content' }
})
export class PageHeaderContentDirective { }

@Directive({
  selector: 'cwt-page-header-avatar, [cwt-page-header-avatar]',
  exportAs: 'cwtPageHeaderAvatar',
  host: { class: 'cwt-page-header-avatar' }
})
export class PageHeaderAvatarDirective { }