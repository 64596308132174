import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getId, isset } from '@cawita/core-front';
import { Observable } from 'rxjs';
import { UserStore } from './user.store';

@Injectable()
export class UserIdInterceptor implements HttpInterceptor {
    constructor(private user: UserStore) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { user } = this.user.getValue();
        const isCompanyRequest = req.url.includes(':userId');

        if (!isset(user) || !isCompanyRequest) return next.handle(req);
        return next.handle(req.clone({
            url: req.url.replace(':userId', getId(user))
        }));
    }
}