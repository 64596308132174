import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isset, selectFile } from '@cawita/core-front';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Directive({
  selector: '[cwtPickFile]',
  host: {
    'style': 'cursor: pointer;',
    '[class.pick-file-over]': 'drop && isOver'
  }
})
export class PickFileDirective {

  @Input('cwtPickFile') accept?: string;
  @Input('cwtPickFileMultiple') multiple: boolean = false;
  @Input('cwtPickFileDrop') @InputBoolean() drop: boolean = false;

  @Output() file = new EventEmitter<File | File[]>();

  public isOver = false;

  constructor(private cdRef: ChangeDetectorRef) { }

  @HostListener('click', ['$event'])
  public onClick($event: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    if (this.multiple) selectFile(this.accept, true).then(files => this.file.emit(files));
    else selectFile(this.accept, false).then(file => this.file.emit(file));
  }

  @HostListener('dragover', ['$event'])
  public onDragOver($event: Event) {
    if (!this.drop) return;

    $event?.stopPropagation();
    $event?.preventDefault();
    this.isOver = true;
    this.cdRef.markForCheck();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave($event: Event) {
    if (!this.drop) return;

    $event?.stopPropagation();
    $event?.preventDefault();
    this.isOver = false;
    this.cdRef.markForCheck();
  }

  @HostListener('drop', ['$event'])
  public onDrop($event: DragEvent) {
    if (!this.drop) return;

    $event?.stopPropagation();
    $event?.preventDefault();
    this.isOver = false;
    const files = fileListToArray($event.dataTransfer?.files);

    this.cdRef.markForCheck();
    if (!files.length) return;
    if (this.multiple) this.file.emit(files);
    else this.file.emit(files[0]);
  }
}

function fileListToArray(fileList: FileList): File[] {
  if (!isset(fileList) || !fileList.length) return [];

  const arr: File[] = [];
  for (let i = 0; i < fileList.length; i++) {
    const itemAtIndex = fileList.item(i);
    if (isset(itemAtIndex)) arr.push(itemAtIndex);
  }
  return arr;
}
