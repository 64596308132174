import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedCommonModule } from '@shared/modules';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RecaptchaHintComponent } from './recaptcha-hint/recaptcha-hint.component';
import { RECAPTCHA_SITE_KEY, RecaptchaComponent } from './recaptcha.component';


@NgModule({
  declarations: [
    RecaptchaComponent,
    RecaptchaHintComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxCaptchaModule,
    SharedCommonModule
  ],
  exports: [
    RecaptchaComponent,
    RecaptchaHintComponent
  ]
})
export class RecaptchaModule {
  static forRoot(siteKey: string): ModuleWithProviders<RecaptchaModule> {
    return {
      ngModule: RecaptchaModule,
      providers: [
        { provide: RECAPTCHA_SITE_KEY, useValue: siteKey }
      ]
    }
  }
}
