import { Injectable } from "@angular/core";
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { UserStore } from "./user.store";

@Injectable()
export class UserAuthListener implements ICwtAuthListener {
    constructor(private user: UserStore) { }

    onLogin(user: any): void { this.user.init(user); }
    onLogout(): void { this.user.clear(); }
}