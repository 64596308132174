import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, OnInit, ViewChild } from '@angular/core';
import { PageHeaderActionsDirective, PageHeaderAvatarDirective, PageHeaderContentDirective, PageHeaderSubtitleDirective, PageHeaderTagsDirective, PageHeaderTitleDirective } from './page-header-cells.directive';

@Component({
  selector: 'cwt-page-header',
  templateUrl: './page-header.component.html',
  host: { class: 'cwt-page-header' }
})
export class PageHeaderComponent {

  @ContentChild(PageHeaderTitleDirective) public title: PageHeaderTitleDirective;
  @ContentChild(PageHeaderSubtitleDirective) public subtitle: PageHeaderSubtitleDirective;
  @ContentChild(PageHeaderTagsDirective) public tags: PageHeaderTagsDirective;
  @ContentChild(PageHeaderActionsDirective) public actions: PageHeaderActionsDirective;
  @ContentChild(PageHeaderContentDirective) public content: PageHeaderContentDirective;
  @ContentChild(PageHeaderAvatarDirective) public avatar: PageHeaderAvatarDirective;

  constructor() { }
}
