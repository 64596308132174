import { CwtApiEntity, CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";

@CwtSchema()
export class EmailRecipient extends CwtApiSchema {
    @CwtProp() email: string;
}

@CwtModel({ name: 'email' })
export class Email extends CwtApiEntity {
    @CwtProp({ ref: EmailRecipient }) recipients: EmailRecipient[];
    @CwtProp() subject: string;
    @CwtProp() contentHtml: string;
    @CwtProp() contentText: string;
}